import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  List,
  Typography
} from '@material-ui/core'
import {
  faBell,
  faEnvelope,
  faInfoCircle,
  faLink,
  faMap,
  faMapMarkerAlt,
  faPhoneAlt
} from '@fortawesome/free-solid-svg-icons'
import { generatePath, useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ListLinkItem from './ListLinkItem'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import pathMap from 'src/paths/pathMap'

const useClasses = makeStyles({
  div: {
    backgroundColor: '#222222',
    color: '#787878',
    paddingTop: '50px',
    paddingBottom: '25px'
  }
})

function ExtendedFooter() {
  const classes = useClasses()
  const history = useHistory()

  function directToSiteContentPage(code) {
    history.push(generatePath(pathMap.SITE_CONTENT_VIEW, { code }))
  }

  function openWindow(newPath){
    window.open(newPath, '_blank', 'noreferrer');
  }

  function redirectPage(newPath){
    history.push(newPath, '_blank', 'noreferrer');
  }

  return (
    <div className={classes.div}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h4'>
              <FontAwesomeIcon icon={faInfoCircle} />
              &nbsp;About Us
            </Typography>
            <List component='nav' dense>
              <ListLinkItem
                primaryText='Who We Are'
                onClick={() => {
                  openWindow("https://globaletrade.services")
                }}
              />
              <ListLinkItem
                primaryText='Missions and values'
                onClick={() => {
                  directToSiteContentPage('missions-values')
                }}
              />
              <ListLinkItem
                primaryText='FAQ'
                onClick={() => {
                  directToSiteContentPage('faq')
                }}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h4'>
              <FontAwesomeIcon icon={faLink} />
              &nbsp;Quick Links
            </Typography>
            <List component='nav' dense>
              <ListLinkItem
                primaryText='Resources and Statistics'
                onClick={() => {
                  directToSiteContentPage('statistics')
                }}
              />
              <ListLinkItem
                primaryText='External Links'
                onClick={() => {
                  directToSiteContentPage('external-links')
                }}
              />
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant='h4'>
              <FontAwesomeIcon icon={faMap} />
              &nbsp;OUR LOCATION
            </Typography>
            <Typography variant='body2'>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              &nbsp;31 Science Park Road, The Crimson, Singapore 117611
            </Typography>
            <Typography variant='body2'>
              <FontAwesomeIcon icon={faPhoneAlt} />
              &nbsp;(65) 6887 7888
            </Typography>
            <Typography variant='body2'>
              <FontAwesomeIcon icon={faEnvelope} />
              &nbsp;sales@smart.com
            </Typography>
            <Typography variant='body2'>
              <FontAwesomeIcon icon={faBell} />
              &nbsp;Mon-Fri: 7.30am - 5pm; Sat-Sun: 8am-12pm
            </Typography>
          </Grid>
          <Grid item container xs={12} justify='center'>
            <Grid item>
              <ButtonGroup variant='text' color='inherit'>
                <Button
                  onClick={() => {
                    openWindow("https://crimsonlogic-northamerica.com/privacy-policy/")
                  }}
                >
                  Privacy Policy
                </Button>
                <Button
                  onClick={() => {
                    // openWindow("https://crimsonlogic-northamerica.com/terms-of-use/")
                    let contactUsPath = '/na-mig/terms-of-use'
                    openWindow(contactUsPath)
                  }}
                >
                  Terms Of Use
                </Button>
                <Button
                  onClick={() => {
                     openWindow("https://www.crimsonlogic.com/saas-general-terms-and-conditions")
                  }}
                >
                  Terms of Service
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ExtendedFooter
