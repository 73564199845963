import React, { useState, useEffect } from 'react'
import {
    Badge,
    makeStyles
} from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}))

function PrivacyPolicy() {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [labelState, setLabelState] = useState("")

    useEffect(() => {
        let label = translatedTextsObject.privacyPolicy;

        let wbLabel;
        if (whiteLabelServiceSettings.length > 0) {
            wbLabel = whiteLabelServiceSettings[0].custom.privacyPolicyLabel

            if (wbLabel != null && wbLabel != undefined) {
                label = wbLabel;
            }
        }
        setLabelState(label)
    }, []);

    function makeTranslatedTextsObject() {
        let privacyPolicy = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.PRIVACY_POLICY
        )

        return {
            privacyPolicy
        }
    }

    function redirectPage() {
        let privacyPolicyPath = "https://crimsonlogic-northamerica.com/privacy-policy/"

        let wbPrivacyPolicyPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbPrivacyPolicyPath = whiteLabelServiceSettings[0].custom.privacyPolicy
        }

        RedirectPage(history, privacyPolicyPath, `/shared/privacy-policy`, wbPrivacyPolicyPath)
    }
    return (
        <Badge>
            <Link
                onClick={redirectPage}
                component="button"
            >
                {labelState}
            </Link>
        </Badge>
    )
}

export default PrivacyPolicy;